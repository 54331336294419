import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#F95109" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            {/* <CFLink href="https://www.facebook.com/Maple-Sushi-Restaurant-1842940762597762/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Maple Sushi Facebook"
              />
            </CFLink> */}
            <CFLink href="https://instagram.com/maplesushi_southsurrey?igshid=YmMyMTA2M2Y=">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Maple Sushi Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#F95109" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            {/* <CFLink href="https://www.facebook.com/Maple-Sushi-Restaurant-1842940762597762/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Maple Sushi Facebook"
              />
            </CFLink> */}
            <CFLink href="https://instagram.com/maplesushi_southsurrey?igshid=YmMyMTA2M2Y=">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Maple Sushi Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
