import React, { useState, useEffect } from 'react'
import { css } from 'emotion'
import { CFView, CFImage, CFLink, PrimaryModal } from 'components'
import { bag, review } from 'images'
import { dispatch } from 'store'

export default () => {
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    const welcomeMessage = dispatch.restaurant.getWelcomeMessage()
    if (welcomeMessage.title) {
      setShowModal(true)
    }
  }, [])

  const closeModal = () => {
    setShowModal(false)
    // localStorage.setItem('welcomeSeen', true)
  }

  return (
    <PrimaryModal
      title="Review Us on Google"
      isOpen={showModal}
      onRequestClose={closeModal}
    >
      <CFView className={styles.container}>
        <CFView column center textCenter w="100%" p="20px">
          <CFView h3 black textCenter mb="8px" ph="45px">
            Thanks for choosing Maple Sushi! Review us on Google and get a free
            shopping bag!
          </CFView>
          <CFLink
            p="10px"
            href="https://www.google.com/search?q=maple+sushi+surrey&oq=maple+sushi+surrey&aqs=chrome.0.0i355i512j46i175i199i512j0i22i30j69i61j69i60l2.2120j0j4&sourceid=chrome&ie=UTF-8#"
            target="_blank"
          >
            <CFImage src={review} w="60%" />
          </CFLink>
          <CFImage src={bag} w="60%" />
          <CFView mt="15px">Free bag available after spending over $30.</CFView>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '600px',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  }),
}
