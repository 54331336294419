import React, { useState } from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import GiftModal from './GiftModal'
import { giftButton, hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          maxHeight="667px"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          alignCenter
        >
          <Header />
          <CFView column center textCenter w="100%">
            <CFView>
              <CFImage
                w="95%"
                src={mobileHeroText}
                alt="Maple Sushi hero text"
                zIndex={98}
              />
            </CFView>
            <CFView pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView w="100%" hover onClick={() => setShowModal(true)}>
              <CFImage
                w="95%"
                maxWidth="300px"
                src={giftButton}
                alt="Gift Card Sale"
              />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          m="0 auto"
          maxWidth="1400px"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyStart
          alignStart
        >
          <Header />
          <CFView column justifyCenter alignStart ml="65px" mt="45px">
            <CFView column justifyStart alignStart>
              <CFImage
                w="580px"
                src={heroText}
                alt="Maple Sushi hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mt="10px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView w="100%" hover onClick={() => setShowModal(true)}>
              <CFImage maxWidth="320px" src={giftButton} alt="Gift Card Sale" />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <GiftModal
        showModal={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </CFView>
  )
}
