import React, { useState, useEffect } from 'react'
import { css } from 'emotion'
import { CFImage, CFView, PrimaryModal } from 'components'
import { promo } from 'images'
import { dispatch } from 'store'

export default props => {
  return (
    <PrimaryModal
      title={'Gift Card Sale'}
      isOpen={props.showModal}
      onRequestClose={props.onRequestClose}
    >
      <CFView className={styles.container}>
        <CFView column textCenter w="100%" p="15px">
          <CFView h3 black textCenter mb="8px">
            Our Corporate Gift Card Program is offered all year round.
          </CFView>
          <CFView h4 black textCenter bold>
            Buy $50+, Get a $5 cash back.
          </CFView>
          <CFView h4 black textCenter bold>
            Buy $100+, Get a $10 cash back.
          </CFView>
          <CFView h4 black textCenter bold mb="8px">
            Buy $500+, Get a $50 cash back.
          </CFView>
          <CFView p="10px">
            <CFImage src={promo} w="100%" />
          </CFView>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    '@media (max-width: 767px)': {
      width: '350px',
    },
  }),
}
