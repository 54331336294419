import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { locationMap, mobileMap } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect w="100%" selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView w="100%">
          <MobileScreen>
            <CFLink href="https://goo.gl/maps/BKevepNkEG5RvsCe8">
              <CFImage src={mobileMap} w="100%" alt="Maple Sushi Map" />
            </CFLink>
          </MobileScreen>
          <DefaultScreen>
            <CFLink
              column
              center
              maxWidth="1400px"
              w="100%"
              href="https://goo.gl/maps/BKevepNkEG5RvsCe8"
            >
              <CFImage
                src={locationMap}
                w="100%"
                alt="Maple Sushi Kitchen Map"
              />
            </CFLink>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
